<script lang="ts" setup>
import themeVars from './themeVars'

const route = useRoute()
const { t } = useI18n()
const head = useLocaleHead({
  addDirAttribute: true,
  addSeoAttributes: true,
})

const vars = reactive(themeVars)

const title = computed(() =>
  t(
    (route.meta.title as string | undefined) ?? 'title.default',
    t('layouts.title'),
  ),
)

useServerHead({
  title,
  link: [
    {
      // 预解析域名
      rel: 'dns-prefetch',
      href: 'https://img.sys-ichi.com',
    },
  ],
  meta: [
    {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1, user-scalable=no,minimal-ui',
    },
    {
      name: 'apple-mobile-web-app-capable',
      content: 'yes',
    },
  ],
})
</script>

<template>
  <Html
    :lang="head.htmlAttrs.lang"
    :dir="head.htmlAttrs.dir"
  >
    <Head>
      <Title>{{ title }}</Title>
      <template
        v-for="link in head.link"
        :key="link.id"
      >
        <Link
          :id="link.id"
          :rel="link.rel"
          :href="link.href"
          :hreflang="link.hreflang"
        />
      </template>
      <template
        v-for="meta in head.meta"
        :key="meta.id"
      >
        <Meta
          :id="meta.id"
          :property="meta.property"
          :content="meta.content"
        />
      </template>
    </Head>
    <Body ontouchstart="">
      <VanConfigProvider :theme-vars="vars" theme-vars-scope="global">
        <slot />
      </VanConfigProvider>
    </Body>
  </Html>
</template>
